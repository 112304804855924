<template>
  <div id="main">
    <div class="container clearfix">
      <div class="other-left">
        <div id="pjax-tiper" class="alert" style="display: none">
          正在加载...
        </div>
        <div id="pjax-container">
          <div class="others clearfix">
            <div class="headtitle">意见反馈</div>
            <div class="result-wrap">
              <h2>提交成功</h2>
              <p>您的反馈已记录，感谢您的支持！</p>
            </div>
            <div class="typeselct js-typeselect">
              <span class="js-feedcheck mr20">
                <span
                  ><input
                    name="feedtype"
                    class="mr10"
                    type="radio"
                    checked="true"
                    value="1" /></span
                >内容意见
              </span>
              <span class="js-feedcheck mr20">
                <span class=""
                  ><input
                    name="feedtype"
                    class="mr10"
                    type="radio"
                    value="2" /></span
                >产品建议
              </span>
              <span class="js-feedcheck mr20">
                <span class=""
                  ><input
                    name="feedtype"
                    class="mr10"
                    type="radio"
                    value="3" /></span
                >技术问题
              </span>
              <span class="js-feedcheck mr20">
                <span class=""
                  ><input
                    name="feedtype"
                    class="mr10"
                    type="radio"
                    value="10" /></span
                >在线投诉
              </span>
              <span class="js-feedcheck mr20">
                <span class=""
                  ><input
                    name="feedtype"
                    class="mr10"
                    type="radio"
                    value="0" /></span
                >其它
              </span>
            </div>

            <div class="feedback-wrap">
              <div class="field-wrap">
                <div>
                  <textarea
                    name="info"
                    maxlength="500"
                    id="info"
                    class="info input-style phd js-jianyi"
                    cols="30"
                    rows="10"
                    placeholder="请填写具体内容帮助我们了解您的意见与建议。"
                  ></textarea>
                  <p class="rlf-tip-wrap"></p>
                </div>
                <p class="fr inputNum js-inputNum">500</p>
                <div>
                  <div class="js-imagesWrap fl imagesWrap"></div>
                  <div class="addImgBtn fl js-addImgBtn">
                    <i class="icon-plus"></i>
                  </div>
                  <div class="avator-btn-wrap">
                    <form
                      target="uploadtarget"
                      action="/user/ajaxuploadpic"
                      method="post"
                      enctype="multipart/form-data"
                    >
                      <input
                        type="file"
                        onclick="this.form.reset();"
                        name="picture[]"
                        class="hide"
                        id="upload"
                        accept="image/jpeg,image/png,image/gif,image/bmp"
                      />
                    </form>
                    <iframe
                      src="about:blank"
                      id="uploadtarget"
                      name="uploadtarget"
                      frameborder="0"
                      style="display: none"
                    ></iframe>
                  </div>
                  <div class="cb"></div>
                  <p class="uploadImgsTip">
                    最多可以上传<span class="color-red">5</span
                    >张图片,图片大小不能超过<span class="color-red">2M</span>
                  </p>
                </div>
                <div>
                  <input
                    type="text"
                    name="contact"
                    id="contact"
                    maxlength="30"
                    class="contact phd input-style"
                    placeholder="请留下真实联系方式（邮箱、QQ），方便我们答疑解惑！"
                  />
                  <p class="rlf-tip-wrap"></p>
                </div>
                <div class="editinfo">
                  <span class="info-tit">相关页面地址：</span>
                  <input
                    type="text"
                    name="url"
                    id="url"
                    class="url phd input input-style"
                    maxlength="225"
                    placeholder="页面地址"
                  />
                  <span class="info-tit">您的位置以及运营商：</span>
                  <div class="location clearfix" id="loc-sys">
                    <div class="l">
                      <span
                        class="js-param-city js-param"
                        id="locate"
                        name="locate"
                        >河北省石家庄市</span
                      >
                      <span
                        class="js-param-yys js-param"
                        id="webserver"
                        name="operator"
                        >联通</span
                      >
                      <span class="" name="ip">101.16.238.46</span>
                    </div>
                    <div class="ctrl r">
                      如您发现判断错误，请<a
                        href="javascript:;"
                        class="js-edit-loc"
                        >立即修改</a
                      >
                    </div>
                  </div>
                  <div class="location-edit clearfix" id="loc-edit">
                    <div class="ctrl r">
                      <a href="javascript:;" class="js-edit-save">保存</a>
                      <a href="javascript:;" class="js-edit-cancel">取消</a>
                    </div>
                    <input
                      type="text"
                      class="input-style ipt ipt-city"
                      maxlength="50"
                    />
                    <input
                      type="text"
                      class="input-style ipt ipt-yys"
                      maxlength="20"
                    />
                  </div>
                </div>
                <div class="btn-wrap clearfix">
                  <div class="captcha-verify-box js-verify-box hide"></div>
                  <button hidefocus="true" id="submit" class="fl">提交</button>
                  <p
                    id="feedback-error"
                    class="rlf-tip-wrap rlf-tip-error feedback-error"
                    style="display: none"
                  ></p>
                </div>
              </div>

              <div class="feedback-content" id="myfeedback">
                <ul class="feedback-nav clearfix tip">
                  <li class="feedHead">
                    <a href="javascript:void(0);" id="Myfeedback">我的反馈</a>
                  </li>
                </ul>

                <ul class="feedback-list" id="feedback-list">
                  <li class="no-data">无反馈信息</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="other-right common-feedback">
        <h4>常见反馈</h4>
        <ul>
          <li>
            <a href="//www.imooc.com/help/detail/105" target="_blank"
              >购买了课程可以退换课吗？</a
            >
          </li>
        </ul>
      </div>
      <div class="other-right">
        <h4>想及时沟通，请加慕粉QQ群！</h4>
        <ul>
          <li>p8课堂软件测试讨论群: 795643692</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data(){
        return {
            List:[

            ]
        }
    }
};
</script>

<style>
#main{
    background: #FFF;

}
.container {
    margin: 0 auto;
    width: 1200px;
    background-color: #fff
}

.container .alert {
    position: absolute;
    z-index: 1030;
    left: 300px
}

.container .other-left {
    float: left;
    position: relative;
    padding-bottom: 50px;
    width: 825px
}

.container .other-left .headtitle {
    margin-bottom: 29px;
    height: 55px;
    font-weight: 700;
    line-height: 55px;
    font-size: 16px;
    color: #14191e;
    border-bottom: 1px solid #b7bbbf
}

.container .other-left .typeselct {
    margin-bottom: 30px;
    padding: 0 24px;
    height: 25px;
    line-height: 25px;
    color: #5e666e
}

.container .other-left .typeselct span {
    display: inline-block
}

.container .other-left .result-wrap {
    display: none;
    text-align: center;
    padding: 100px 0;
    width: 100%
}

.container .other-left .result-wrap p {
    line-height: 32px
}

.container .other-left .result-wrap a {
    margin-top: 10px;
    display: inline-block;
    color: #fff;
    font-size: 14px;
    background-color: #39b94e;
    height: 36px;
    line-height: 36px;
    width: 120px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    transition: background-color .2s;
    -webkit-transition: background-color .2s;
    -moz-transition: background-color .2s;
    -o-transition: background-color .2s
}

.container .other-left .feedback-wrap {
    padding: 0 24px;
    width: 100%;
    box-sizing: border-box
}

.container .other-left .feedback-wrap .info {
    width: 776px;
    height: 140px;
    resize: none;
    overflow: hidden
}

.container .other-left .feedback-wrap .inputNum {
    position: relative;
    top: -62px;
    left: -12px
}

.container .other-left .feedback-wrap .rlf-tip-wrap {
    font-size: 12px;
    height: 30px;
    line-height: 30px
}

.container .other-left .feedback-wrap .rlf-tip-error {
    padding-left: 15px;
    background: url(/static/images/rl-sprite.png) no-repeat -108px -933px;
    color: #be3948
}

.container .other-left .feedback-wrap .input-style {
    padding: 10px 7px;
    border: 1px solid #d0d6d9;
    font-size: 14px;
    transition: border-color .3s ease;
    -webkit-transition: border-color .3s ease;
    -moz-transition: border-color .3s ease;
    -o-transition: border-color .3s ease;
    box-sizing: border-box
}

.container .other-left .feedback-wrap .input-style:focus {
    border-color: #7bd089
}

.container .other-left .feedback-wrap .error-field {
    border-color: #be3948
}

.container .other-left .feedback-wrap .addImgBtn {
    margin-bottom: 20px;
    width: 80px;
    height: 80px;
    border: 1px solid #dfe3e6;
    color: #b7bbbf;
    font-size: 30px;
    text-align: center;
    line-height: 80px;
    cursor: pointer
}

.container .other-left .feedback-wrap .uploadImgsTip {
    margin-bottom: 30px
}

.container .other-left .feedback-wrap .color-red {
    color: #ef1300
}

.container .other-left .feedback-wrap .contact {
    padding: 0 10px;
    width: 776px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    vertical-align: middle
}

.container .other-left .feedback-wrap .delete {
    position: absolute;
    right: 28px;
    top: 5px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-image: url(/static/img/delete.png);
    font-size: 20px;
    color: #f01414;
    text-align: center;
    font-weight: 700;
    cursor: pointer
}

.container .other-left .feedback-wrap .editinfo {
    padding: 20px;
    margin-bottom: 30px;
    margin-top: 20px;
    overflow: hidden;
    background: #f4f7f8
}

.container .other-left .feedback-wrap .editinfo .info-tit {
    display: block;
    margin-bottom: 10px;
    color: #787d82;
    font-size: 14px;
    line-height: 26px
}

.container .other-left .feedback-wrap .editinfo .url {
    margin-bottom: 28px;
    padding: 0 10px;
    width: 736px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    vertical-align: middle
}

.container .other-left .feedback-wrap .editinfo .location span {
    margin-right: 16px;
    white-space: nowrap;
    overflow: hidden
}

.container .other-left .feedback-wrap .editinfo .location .ctrl {
    font-size: 12px
}

.container .other-left .feedback-wrap .editinfo .location .ctrl a {
    color: #7bd089
}

.container .other-left .feedback-wrap .editinfo .location-edit {
    display: none
}

.container .other-left .feedback-wrap .editinfo .location-edit .ipt {
    margin-right: 20px;
    padding: 10px;
    width: 180px;
    border: 1px solid #d0d6d9;
    resize: none;
    box-sizing: content-box
}

.container .other-left .feedback-wrap .editinfo .location-edit .ipt:focus {
    border-color: #7bd089;
    outline: 0;
    box-shadow: none
}

.container .other-left .feedback-wrap .editinfo .location-edit .ctrl {
    line-height: 40px;
    font-size: 12px
}

.container .other-left .feedback-wrap .editinfo .location-edit .ctrl a {
    margin-left: 20px;
    color: #7bd089
}

.container .other-left .feedback-wrap .btn-wrap {
    position: relative
}

.container .other-left .feedback-wrap .btn-wrap .captcha-verify-box {
    position: absolute;
    right: -107px;
    bottom: 65px;
    padding: 6px;
    width: 320px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #d0d6d9;
    z-index: 9
}

.container .other-left .feedback-wrap .btn-wrap .captcha-verify-box:after {
    position: absolute;
    bottom: -11px;
    left: 155px;
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 10px solid #d0d6d9;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index: 9
}

.container .other-left .feedback-wrap .btn-wrap .captcha-verify-box:before {
    position: absolute;
    bottom: -9px;
    left: 156px;
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 9px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    z-index: 10
}

.container .other-left .feedback-wrap button {
    float: right;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    font-size: 14px;
    background-color: #39b94e;
    height: 50px;
    line-height: 40px;
    width: 120px;
    cursor: pointer;
    text-align: center;
    transition: background-color .2s;
    -webkit-transition: background-color .2s;
    -moz-transition: background-color .2s;
    -o-transition: background-color .2s
}

.container .other-left .feedback-wrap .feedback-error {
    padding-right: 20px;
    padding-left: 15px;
    float: right;
    line-height: 50px;
    background: url(/static/images/rl-sprite.png) no-repeat -108px -923px;
    color: #be3948
}

.container .feedback-content {
    float: left;
    width: 100%;
    margin-top: 40px
}

.container .feedback-content .tip {
    color: #83888c
}

.container .feedback-content .feedback-list .feedback-item,.container .feedback-content .feedback-nav {
    border-bottom: 1px solid #b7bbbf
}

.container .feedback-content .feedHead {
    margin-left: 0;
    height: 45px;
    text-align: left;
    color: #14191e;
    font-size: 16px;
    line-height: 45px;
    cursor: default
}

.container .feedback-content .feedback-list .feedback-item {
    padding: 25px 24px;
    box-sizing: border-box;
    color: #14191e
}

.container .feedback-content .feedback-list .feedback-item p {
    line-height: 24px;
    word-wrap: break-word;
    word-break: break-all
}

.container .feedback-content .feedback-list .feedback-item .pics {
    padding: 10px 0 0
}

.container .feedback-content .feedback-list .feedback-item .photos-thumb {
    overflow: hidden;
    margin-bottom: 10px
}

.container .feedback-content .feedback-list .feedback-item .photos-thumb li {
    float: left;
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 8px 12px 0;
    border: 1px solid transparent
}

.container .feedback-content .feedback-list .feedback-item .photos-thumb li img {
    width: 100px;
    height: 100px;
    cursor: url(/static/img/feedback/zoom_in.png),url(/static/img/feedback/zoom_in.cur),auto
}

.container .feedback-content .feedback-list .feedback-item .photos-thumb li.current {
    border-color: #f01400
}

.container .feedback-content .feedback-list .feedback-item .photos-thumb li.current img {
    cursor: url(/static/img/feedback/zoom_out.png),url(/static/img/feedback/zoom_out.cur),auto
}

.container .feedback-content .feedback-list .feedback-item .photos-thumb li.current .photos-arrow {
    display: block
}

.container .feedback-content .feedback-list .feedback-item .photos-thumb .photos-arrow {
    display: none;
    width: 0;
    height: 0;
    line-height: 0;
    font-size: 0;
    border: transparent 8px dashed;
    border-top: 8px solid #f01400;
    position: absolute;
    left: 30px;
    bottom: -16px;
    background: 0 0
}

.container .feedback-content .feedback-list .feedback-item .photo-viewer {
    display: none
}

.container .feedback-content .feedback-list .feedback-item .photo-viewer .photo-viewer-inner {
    position: relative;
    float: left;
    max-width: 400px
}

.container .feedback-content .feedback-list .feedback-item .photo-viewer img {
    width: 400px;
    cursor: url(/static/img/feedback/zoom_out.png),url(/static/img/feedback/zoom_out.cur),auto
}

.container .feedback-content .feedback-list .feedback-item .bottom-box {
    margin-top: 10px;
    color: #c8cdd2;
    font-size: 12px
}

.container .feedback-content .feedback-list .feedback-item .reply {
    padding: 16px 20px;
    margin-top: 18px;
    color: #787d82;
    background-color: #f6f6f6;
    font-size: 12px;
    line-height: 24px
}

.container .feedback-content .feedback-list .feedback-item .imooc-manager {
    color: #f01414
}

.container .feedback-content .feedback-list .no-data {
    padding-top: 50px;
    color: #c8cdd2;
    line-height: 200px;
    height: 200px;
    font-size: 24px;
    border: none;
    text-align: center
}

.container .other-right {
    float: right;
    margin-top: 20px;
    padding: 24px;
    width: 327px;
    box-sizing: border-box;
    background: #f3f5f7;
    overflow-x: hidden;
    font-size: 14px;
    font-family: "Microsoft YaHei";
    color: #787d82;
    line-height: 2.143;
    text-align: justifyLeft
}

.container .other-right h4 {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    color: #07111b
}

.container .other-right li {
    color: #4d555d;
    font-size: 14px
}

.container .mr24 {
    margin-right: 24px
}

.container .common-feedback a:hover {
    color: #0c0!important
}

.bind-weixin-box {
    width: 256px;
    height: 300px;
    text-align: center
}

.bind-weixin-box .bind-weixin-title {
    position: relative;
    top: -20px;
    height: 36px;
    font-family: PingFangSC-Semibold;
    font-size: 24px;
    color: #1c1f21;
    display: inline-block;
    line-height: 36px;
    font-weight: 600
}

.bind-weixin-box .bind-weixin-title i {
    margin-right: 8px;
    color: #37f;
    font-size: 32px;
    float: left;
    line-height: 36px
}

.bind-weixin-box .bind-weixin-content {
    position: relative;
    top: -20px;
    font-size: 14px;
    color: #545c63;
    text-align: center;
    line-height: 24px;
    font-weight: 400
}

.bind-weixin-box .bind-weixin-content span {
    font-size: 16px;
    color: #1c1f21;
    line-height: 24px;
    font-weight: 600;
    margin: 16px 0 4px;
    display: block
}

.bind-weixin-box .bind-weixin-img {
    position: relative;
    top: -20px;
    text-align: center;
    font-size: 12px;
    line-height: 1
}

.bind-weixin-box .bind-weixin-img img {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    display: block
}

</style>
